import React from 'react';
import SidebarAdmin from '../shared-components/Sidebar-admin';



const HomeAdmin = () => {
  

  return (
    <div>
      <SidebarAdmin/>
      
    </div>
  );
};

export default HomeAdmin;